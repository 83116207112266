import { RouteLocationNormalized, Router } from 'vue-router'
import { msal } from '../../authConfig'
import { PublicClientApplication } from '@azure/msal-browser'

export function registerGuard(router: Router) {
  router.beforeEach(
    async (to: RouteLocationNormalized) => {
      const { msalInstance } = await msal()
      if (to.meta.requiresAuth) {

        return await isAuthenticated(msalInstance)
      }

      return true
    }
  )
}

export async function isAuthenticated(
  instance: PublicClientApplication
): Promise<boolean> {
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts()
      if (accounts.length > 0) {
        return true
      }

      return instance
        .loginRedirect()
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    })
    .catch(() => {
      return false
    })
}
