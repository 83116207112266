import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/App/ui/Home.vue'
import store from '../store/index'
import { nextTick } from 'vue'
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/dashboard',
    name: 'Home',
    meta: {
      requiresAuth: true
    },
    component: Home,
    children: [
      {
        path: '',
        name: 'Dashboard',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/features/dashboard/ui/Dashboard.vue')
      },
      {
        path: 'indicators',
        name: 'Indicators',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/features/indicators/ui/Indicators.vue'),
      },
      {
        path: 'alerts',
        name: 'Alerts',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/features/alerts/ui/Alerts.vue')
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/features/settings/ui/Settings.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'EcoSystem',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/features/ecosystem/ui/Ecosystem.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'k-state-selected',
  routes
})

registerGuard(router)

export default router
