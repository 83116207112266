import { AccountInfo } from '@azure/msal-common'
import { createStore } from 'vuex'
import { msal } from '../../authConfig'

const handleResponse = async (response: any) => {
  let account!: AccountInfo
  let token!: string
  let expiresOn: number | undefined
  const { msalInstance, msalConfig } = await msal()
  const currentAccounts = msalInstance.getAllAccounts()
  if (currentAccounts.length === 0) {
    // Add choose account code here
    await msalInstance.loginRedirect()
  } else if (currentAccounts.length === 1) {
    account = currentAccounts[0]
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: [msalConfig.auth.clientId + '/.default'],
        account: currentAccounts[0]
      })
      token = response.accessToken
      expiresOn = response.expiresOn?.getTime()
    } catch (error) {
      sessionStorage.clear()
      localStorage.clear()
    }
  }

  return { account, token, expiresOn }
}

interface State {
  account?: AccountInfo | undefined
  token?: string | null
}

export default createStore<State>({
  state: {
    account: undefined,
    token: sessionStorage.getItem('adal.idtoken')
  },
  mutations: {
    SET_USER_DATA(state, data) {
      state.account = data.account
    }
  },
  actions: {
    async SignIn({ commit }) {
      const { msalInstance } = await msal()

      return msalInstance
        .handleRedirectPromise()
        .then(async (res) => {
          const data: any = await handleResponse(res)

          commit('SET_USER_DATA', data)
          if (data.token || data.expiresOn) {
            sessionStorage.setItem('adal.idtoken', data.token)
            sessionStorage.setItem('expiresOn', data.expiresOn)
            localStorage.setItem('account', JSON.stringify(data.account))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },
  getters: {
    getUserName: (state) => {
      let name: string | undefined
      let shortName: string | undefined

      if (state.account) {
        name = state.account.name
        shortName = state.account.name
          ?.split(' ')
          .map((i: string) => i[0])
          .join('')
      }

      return { name, shortName }
    }
  },
  modules: {}
})
