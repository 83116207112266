import { createApp } from 'vue'
import App from './App/ui/App.vue'
import router from './App/router'
import store from './App/store'
import './assets/app.scss'
import { emitter } from './App/events/eventbus'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App);
app.provide('emitter', emitter)

app.use(PerfectScrollbar).use(store).use(router).mount('#app');